import React from "react"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"

import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import { Formik, Form, Field, ErrorMessage } from "formik"
import "../i18n"
import useChangeLanguage from "../hooks/useChangeLanguage"

export default function Contact({ data, pageContext }) {
  const { t } = useTranslation()
  const { locale } = pageContext
  useChangeLanguage({ locale })

  return (
    <MainLayout alternativeSlug={locale === 'tr-TR' ? '/contact' : '/iletisim'} locale={locale}>
      <Seo
        title={`${data.strapiContact.seo.title}`}
        description={`${data.strapiContact.seo.description}`}
      />

      <PageHeader bannerBg="#EC7B03">
        <div className="page-header-image container-iframe">
          <iframe
            title="location"
            src={data.strapiContact.embedSrc}
            style={{ border: 0 }}
            width="590"
            height="340"
            loading="lazy"
            allowfullscreen
            className="contact-header-image"
          />
        </div>

        <div className="contact-header-text">
          <h3>{t("contactPage.address")}</h3>
          <p>{data.strapiContact.address}</p>

          <h3>{t("contactPage.telephone")}</h3>
          <p>{data.strapiContact.telephone}</p>

          <h3>{t("contactPage.emails")}</h3>
          <p>
            {data.strapiContact.email.split("\n").map(item => (
              <>
                {item}
                <br />
              </>
            ))}
          </p>
        </div>
      </PageHeader>

      <div className="contact-container-fluid">
        <div className="container">
          <Formik
            onSubmit={(values, actions) => {
              fetch(`${process.env.API_URL}/api/messages`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ data: values }),
              })
                .then(() => {
                  alert(t("contactPage.success"))
                  actions.resetForm()
                })
                .catch(() => {
                  alert(t("contactPage.errors.general"))
                })
                .finally(() => actions.setSubmitting(false))
            }}
            validate={values => {
              const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              const errors = {}
              if (!values.fullName) {
                errors.fullName = t("contactPage.errors.fullName")
              }
              if (!values.email || !emailRegex.test(values.email)) {
                errors.email = t("contactPage.errors.email")
              }
              if (!values.message) {
                errors.message = t("contactPage.errors.message")
              }
              if (!values.subject) {
                errors.subject = t("contactPage.errors.subject")
              }
              return errors
            }}
            initialValues={{
              fullName: "",
              email: "",
              subject: "",
              message: "",
              telephone: "",
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="contact-form">
                <h3 className="contact-form-title">{t("contactPage.title")}</h3>
                <div className="input-area">
                  <Field
                    name="fullName"
                    placeholder={`${t("contactPage.fullName")}`}
                  />
                  <ErrorMessage
                    name="fullName"
                    className="error-message"
                    component="span"
                  />
                </div>

                <div className="input-area">
                  <Field
                    name="subject"
                    placeholder={`${t("contactPage.subject")}`}
                  />
                  <ErrorMessage
                    name="subject"
                    className="error-message"
                    component="span"
                  />
                </div>

                <div className="input-area">
                  <Field
                    name="email"
                    placeholder={`${t("contactPage.email")}`}
                  />
                  <ErrorMessage
                    name="email"
                    className="error-message"
                    component="span"
                  />
                </div>

                <div className="input-area">
                  <Field
                    name="telephone"
                    placeholder={`${t("contactPage.telephone")}`}
                  />
                  <ErrorMessage
                    name="telephone"
                    className="error-message"
                    component="span"
                  />
                </div>

                <div className="input-area">
                  <Field
                    component="textarea"
                    rows={3}
                    name="message"
                    placeholder={`${t("contactPage.message")}`}
                  />
                  <ErrorMessage
                    name="message"
                    className="error-message"
                    component="span"
                  />
                </div>

                <button type="submit" className="btn btn-danger">
                  {t("contactPage.send")}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </MainLayout>
  )
}

export const contactQuery = graphql`
  query ($id: String!) {
    strapiContact(id: { eq: $id }) {
      email
      telephone
      address
      embedSrc
      locale
      seo {
        title
        description
        keywords
      }
    }
  }
`
